import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

//import NavBar from "../components/navbar"
import Layout from "../components/layout"
import Seo from "../components/seo"

const StrategiesPage = () => (
	<Layout>
		<Seo title="Strategies"/>
		<div className="container pt-4">
			<div className="row">
				<div className="col">				
					<h3>Strategies</h3>
					<p>Olymp Trade robot belobot independently trades in accordance with the chosen strategy. Belobot continuously monitors the rates of the ten most profitable pairs and concludes deals when the strategy condition is triggered.</p>
					<p>If you have your own trading strategy, you can share it on the <Link to="/contacts">contact page</Link>.</p>

					<div className="card mb-4 bg-light bg-gradient">
						<div className="card-header">
							<strong>#1 Deviation from the trend</strong>
						</div>
						
						<div className="card-body">
							The robot monitors the intersection of SMA (2 min.) And SMA (4 min.). If the two-minute trend crosses the four-minute trend, the robot enters a trade following the four-minute trend.
							<div className="row">
								<div className="col">										
									<table className="table">
										<tbody>
											<tr>
												<th scope="row">risk</th>
												<td>low</td>
											</tr>
											<tr>
												<th scope="row">deal time </th>
												<td>2 min.</td>
											</tr>
											<tr>
												<th scope="row">frequency of deals</th>
												<td colspan="2">depends on market activity</td>
											</tr>
											<tr>
												<th scope="row">profitable trades</th>
												<td colspan="2">~80%</td>
											</tr>
											<tr>
												<th scope="row">time to trade</th>
												<td colspan="2">explore</td>
											</tr>												
										</tbody>
									</table>
								</div>
								<div className="col">
								</div>											
							</div>
						</div>
						
						<div className="card-footer text-muted">
							You can help test this strategy. If you know what time it brings the most income, please <Link to="/contacts">let us know</Link>!
						</div>

					</div>
				</div>	

			</div>		
		</div>
		
		
	</Layout>
)

export default StrategiesPage